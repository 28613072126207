<template>
  <draggable
    class="dragArea"
    tag="ul"
    handle=".drag-handle"
    animation="200"
    :filter="'.no-drag'"
    :list="listaDeFiltros"
    :group="{ name: 'group-' + group }"
    :prevent-on-filter="true"
    :options="{
      disabled: !isDraggable
    }"
    :disabled="possuiFiltroObrigatorio(listaDeFiltros)"
  >
    <li
      v-for="(el, idx) in listaDeFiltros"
      :key="el.name"
      class="main-li"
      :class="[
        `cor-da-hierarquia${el.hierarquia}`,
        { 'no-drag': !el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id },
        {
          'drag-handle': !possuiFiltroObrigatorio(listaDeFiltros)
        },
        {
          'filtro-obrigatorio': possuiFiltroObrigatorio(el)
        }
      ]"
    >
      <!--  ---------------------    RADIO BUTTON TO e_ou -------------------------- -->
      <div class="card-toolbar">
        <b-form-radio-group
          v-if="el.hasOwnProperty('mfi_e_ou')"
          v-model="el.mfi_e_ou"
          :options="agrupamento.condicionais"
          class="logica-condicional-input"
          :class="[
            `border-hierarchy-${el.hierarquia}`,
            { 'non-editable': !!el.modelo_filtro_obrigatorio_id }
          ]"
          required
          :buttons="true"
          style="z-index: 10"
        ></b-form-radio-group>
      </div>
      <div
        class="d-flex"
        :class="
          !(Array.isArray(el.listaDeFiltros) && el.listaDeFiltros.length)
            ? 'w-100'
            : ''
        "
      >
        <div
          v-if="!(Array.isArray(el.listaDeFiltros) && el.listaDeFiltros.length)"
          class="row inputs-main-container"
        >
          <div class="col-4 label-input-drag-container">
            <pv-dropdown
              v-model="el.fil_campo"
              :options="camposFiltroComputed"
              dataKey="value"
              required
              placeholder="Nenhum selecionado"
              class="form-control"
              optionLabel="text"
              optionValue="value"
              emptyFilterMessage="Nenhum resultado"
              :filter="true"
              appendTo="body"
              @change="
                modificarFiltroIdDoCampo(
                  el,
                  `input-valor-${idx}-${el.hierarquia}`
                )
              "
              :class="!isDraggable ? 'undraggable-input' : ''"
              :disabled="!!el.modelo_filtro_obrigatorio_id"
            />
            <label class="label-input-drag">Campo</label>
          </div>
          <div class="col-3 label-input-drag-container">
            <pv-dropdown
              v-model="el.ope_titulo"
              :options="operadoresTitulo"
              optionLabel="text"
              optionValue="value"
              appendTo="body"
              required
              :filter="true"
              @change="verificarOperadorDeTituloParaSalvarNoId(el)"
              :class="!isDraggable ? 'undraggable-input' : ''"
              class="form-control"
              :disabled="!!el.modelo_filtro_obrigatorio_id"
            />
            <label class="label-input-drag">Condição</label>
          </div>
          <div
            class="label-input-drag-container d-flex container-do-input-adriano"
            :class="verificarCamposDuplos(el) ? 'col-2' : 'col-4'"
          >
            <input
              v-if="
                (!el.isContextFunction && !el.isFiltroDinamicoInput) ||
                (!el.isFiltroDinamico && !el.isContextFunction)
              "
              class="form-control"
              v-model="el.mfi_valor"
              :disabled="
                !isDraggable ||
                el.operador_id === 7 ||
                el.operador_id === 8 ||
                (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id)
              "
              :type="el.type"
              :id="`input-valor-${idx}-${el.hierarquia}`"
            />

            <Select2
              v-if="el.isFiltroDinamico && el.isFiltroDinamicoInput"
              v-model="el.filtroDinamicoMockValue"
              :options="el.dinamycFilterOptions"
              @change="findValueDinamicFilter($event, el)"
              class="form-control"
              style="position: relative !important"
              :disabled="
                !el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id
              "
            />
            <Select2
              v-model="el.contexto_nome"
              v-if="el.isContextFunction"
              :options="funcoesContexto"
              @change="findValueContextFunction($event, el)"
              class="form-control"
              style="position: relative !important"
              :disabled="
                !isDraggable ||
                (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id)
              "
            />
            <!-- <b-form-select
              v-if="el.isContextFunction"
              v-model="el.contexto_id"
              :options="funcoesContexto"
              required
              :class="!isDraggable ? 'undraggable-input' : ''"
            ></b-form-select> -->

            <label class="label-input-drag">{{
              el.isFiltroDinamico && el.isFiltroDinamicoInput
                ? "Filtro Dinâmico"
                : !el.isContextFunction
                ? "Valor"
                : "Função de Contexto"
            }}</label>
          </div>

          <div
            v-if="verificarCamposDuplos(el)"
            class="label-input-drag-container col-2"
          >
            <input
              type="text"
              class="form-control"
              v-model="el.mfi_valor_2"
              :disabled="
                !isDraggable ||
                (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id)
              "
            />
            <label class="label-input-drag">Valor 2</label>
          </div>
          <div
            v-if="!verificarCamposDuplos(el) && !el.isFiltroDinamico"
            class="label-input-drag-container switch-container col-1"
          >
            <InputSwitch
              v-model="el.isContextFunction"
              v-tooltip="'Função de Contexto'"
              @click="contextFunctionSwitch(el)"
              :disabled="
                !isDraggable ||
                (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id)
              "
            />
          </div>
          <div
            v-if="el.isFiltroDinamico"
            class="label-input-drag-container switch-container col-1"
          >
            <InputSwitch
              v-model="el.isFiltroDinamicoInput"
              v-tooltip="'Filtro Dinâmico'"
              @click="dinamicFilterSwitch(el)"
              :disabled="
                !isDraggable ||
                (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id)
              "
            />
          </div>
        </div>
        <div
          class="buttons-container"
          :class="
            Array.isArray(el.listaDeFiltros) && el.listaDeFiltros.length
              ? 'flex-column'
              : ''
          "
          v-if="isDraggable && !possuiFiltroObrigatorio(el)"
        >
          <b-button
            v-if="group === 0 || group === 1 || group === 2"
            :variant="
              !!el.modelo_filtro_obrigatorio_id ? 'disabled' : 'outline-primary'
            "
            :class="[
              'button-plus',
              { 'non-editable': !!el.modelo_filtro_obrigatorio_id }
            ]"
            @click="adicionarElemento(el)"
            style="background: rgba(255, 255, 255, 0.3)"
            :disabled="possuiFiltroObrigatorio(el)"
          >
            +</b-button
          >
          <b-button
            :variant="
              !!el.modelo_filtro_obrigatorio_id ? 'disabled' : 'outline-danger'
            "
            class="button-plus d-block"
            :class="[
              'button-plus',
              'd-block',
              { 'non-editable': !!el.modelo_filtro_obrigatorio_id }
            ]"
            style="background: rgba(255, 255, 255, 0.3)"
            @click="removerElemento(el, el.hierarquia, el.modelo_filtro_id)"
          >
            -</b-button
          >
          <b-button
            v-if="!possuiFiltroObrigatorio(listaDeFiltros)"
            :variant="
              !!el.modelo_filtro_obrigatorio_id ? 'disabled' : 'outline-warning'
            "
            :class="[
              'button-plus',
              'd-block',
              { 'non-editable': !!el.modelo_filtro_obrigatorio_id }
            ]"
            style="background: rgba(255, 255, 255, 0.3)"
            @click="duplicarFiltro(el, idx, el.listaDeFiltros)"
          >
            <i class="flaticon2-copy"></i>
          </b-button>
        </div>
      </div>
      <nested-draggable
        :group="group + 1"
        :listaDeFiltros="el.listaDeFiltros"
        :camposPossiveisDoFiltro="camposPossiveisDoFiltro"
        :opreadoresFiltro="opreadoresFiltro"
        :isDraggable="isDraggable"
        :sistemaLicenciadaId="sistemaLicenciadaId"
        :contextFunctions="contextFunctions"
      />
    </li>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import InputSwitch from "primevue/inputswitch";
import Tooltip from "primevue/tooltip";
import API_LINKS from "../../api.links";
import ApiService from "@/core/services/api.service";
import Select2 from "v-select2-component";
import Utils from "@/core/utils/Utils";

export default {
  directives: {
    tooltip: Tooltip
  },
  props: {
    listaDeFiltros: {
      required: true,
      type: Array
    },
    group: {
      required: true,
      type: Number
    },
    camposPossiveisDoFiltro: {
      required: true,
      type: Array
    },
    opreadoresFiltro: {
      required: true,
      type: Array
    },
    isDraggable: {
      required: true,
      type: Boolean
    },
    sistemaLicenciadaId: {
      required: false
    },
    contextFunctions: {
      required: false
    }
  },
  components: {
    draggable,
    InputSwitch,
    Select2
  },
  name: "nested-draggable",
  data() {
    return {
      isFiltroDinamico: false,
      isContextFunction: false,
      isCampoNull: false,
      funcoesContexto: [],
      dinamycFilterOptionsWithObj: [],
      dinamycFilterOptions: [],
      funcoesContextoTodas: [],
      agrupamento: {
        condicoes: [
          { ope_titulo: "Maior Que", ope_sinal: ">" },
          { ope_titulo: "Menor Que", ope_sinal: "<" },
          { ope_titulo: "Igual", ope_sinal: "=" },
          { ope_titulo: "Diferente", ope_sinal: "<>" },
          {
            ope_titulo: "Não Contém (Em nenhuma posição)",
            ope_sinal: "%NOT_LIKE%"
          },
          { ope_titulo: "Contém (Em qualquer posição)", ope_sinal: "%LIKE%" }
        ],
        campos: [
          "nome",
          "idade",
          "sexo",
          "agenda_status",
          "exame_classe_id",
          "exame_classe_nome",
          "agenda_dia_nome",
          "agenda_data_en",
          "agenda_dia_nome",
          "agenda_data_en"
        ],
        condicionais: [
          { text: "E", value: "AND" },
          { text: "OU", value: "OR" }
        ]
      }
    };
  },
  methods: {
    getDinamicFilterList(element, campoObjeto, filtroMfiValor) {
      ApiService.get(
        API_LINKS.campanha +
          `/filtro-consulta/${this.sistemaLicenciadaId}/${campoObjeto.filtro_id}`
      )
        .then((res) => {
          if (res.data.requisicao.sucesso) {
            const uid = res.data.requisicao.uid;
            ApiService.get(
              API_LINKS.campanha +
                `/resposta-filtro-consulta/${this.sistemaLicenciadaId}/${uid}/${campoObjeto.filtro_id}`
            )
              .then((res2) => {
                if (Array.isArray(res2.data.requisicao.requisicao.resultado)) {
                  element.dinamycFilterOptions =
                    res2.data.requisicao.requisicao.resultado.map(
                      (v) => v.value
                    );
                  element.dinamycFilterOptionsWithObj =
                    res2.data.requisicao.requisicao.resultado;
                } else {
                  element.isFiltroDinamicoInput = false;
                  this.$forceUpdate();
                }

                if (filtroMfiValor) {
                  element.filtroDinamicoMockValue =
                    element.dinamycFilterOptionsWithObj.find(
                      (v) => v.id == filtroMfiValor
                    ).value;
                }

                this.$forceUpdate();
              })
              .catch((e) => {
                element.mfi_valor = "";
                element.filtroDinamicoMockValue = "";
                this.$emit("nestedErrorToast", {
                  severity: "warn",
                  summary: "Erro nos Filtros Dinâmicos",
                  detail: `Campanha sem filtros dinâmicos ou falha na requisição, tente novamente.`,
                  life: 7000
                });
                element.isFiltroDinamicoInput = false;
                this.$forceUpdate();
              });
          }
        })
        .catch((e) => {
          this.$emit("nestedErrorToast", {
            severity: "warn",
            summary: "Erro nos Filtros Dinâmicos",
            detail: `Campanha sem filtros dinâmicos ou falha na requisição, tente novamente.`,
            life: 7000
          });
          element.isFiltroDinamicoInput = false;
          this.$forceUpdate();
        });
    },
    findValueDinamicFilter(clientName, element) {
      element.mfi_valor = element.dinamycFilterOptionsWithObj.find(
        (v) => v.value === clientName
      ).id;
    },
    findValueContextFunction(funcaoContextoNome, element) {
      element.mfi_valor = this.funcoesContextoTodas.find(
        (v) => v.con_titulo === funcaoContextoNome
      ).contexto_id;
      element.contexto_id = element.mfi_valor;
    },
    inputType(element) {
      if (element.operador_id === 6) {
        return "number";
      }
      return "text";
    },
    contextFunctionSwitch(element) {
      if (element.mfi_valor === "") {
        element.contexto_nome = "";
        element.contexto_id = null;
      } else {
        element.mfi_valor = "";
        element.contexto_nome = "";
        element.contexto_id = null;
      }
      this.$forceUpdate();
    },
    dinamicFilterSwitch(element) {
      const campoObjeto = this.camposPossiveisDoFiltro.find((v) => {
        return v.fil_campo === element.fil_campo;
      });
      if (
        !element.hasOwnProperty("dinamycFilterOptionsWithObj") ||
        element.dinamycFilterOptionsWithObj.length === 0
      ) {
        this.getDinamicFilterList(element, campoObjeto, element.mfi_valor);
        element.isFiltroDinamicoInput = false;
      }
      this.$forceUpdate();
    },
    duplicarFiltro(element, idx, elementListaDeFiltros) {
      const stringElement = JSON.stringify(element);
      this.listaDeFiltros.splice(idx, 0, JSON.parse(stringElement));
    },

    modificarFiltroIdDoCampo(element, filtroMfiValor) {
      element.isFiltroDinamico = false;
      element.isFiltroDinamicoInput = false;
      let response;
      const campoObjeto = this.camposPossiveisDoFiltro.find((v) => {
        return v.fil_campo === element.fil_campo;
      });

      if (campoObjeto) {
        // if (campoObjeto.fil_mascara === "int") {
        //   element.type = "number";
        // } else
        // if (campoObjeto.fil_mascara === "string") {
        element.type = "text";
        // }

        if (campoObjeto.fil_tipo === "DIF") {
          element.isFiltroDinamico = true;
          element.isFiltroDinamicoInput = false;
          // this.getDinamicFilterList(element, campoObjeto, filtroMfiValor);
        }
        element.filtro_id = campoObjeto.filtro_id;
        this.$forceUpdate();
      }

      this.$forceUpdate();
    },
    verificarOperadorDeTituloParaSalvarNoId(element) {
      const operadorFiltroObjeto = this.opreadoresFiltro.find((v) => {
        return v.ope_titulo === element.ope_titulo;
      });
      if (element.ope_titulo.indexOf("IN")) {
        element.type = "string";
      }
      element.operador_id = operadorFiltroObjeto.operador_id;
      element.ope_sinal = operadorFiltroObjeto.ope_sinal;
      if (element.operador_id === 7 || element.operador_id === 8) {
        this.isCampoNull = true;
      } else {
        this.isCampoNull = false;
      }
    },
    verificarCamposDuplos(element) {
      if (element.operador_id === 9 || element.operador_id === 10) {
        element.isContextFunction = false;
        return true;
      }
      return false;
    },

    adicionarElemento(elemento) {
      this.listaDeFiltros[
        this.listaDeFiltros.indexOf(elemento)
      ].listaDeFiltros.push({
        hierarquia: elemento.hierarquia + 1,
        filtro_id: 1,
        ope_titulo: "",
        mfi_valor: "",
        mfi_e_ou: "AND",
        mfi_valor_2: null,
        operador_id: 12,
        listaDeFiltros: [],
        contexto_id: null
      });

      delete elemento.mfi_e_ou;
      delete elemento.mfi_valor_2;
      delete elemento.operador_id;
      delete elemento.contexto_id;
      delete elemento.filtro_id;
      delete elemento.ope_titulo;
      delete elemento.mfi_valor;
    },
    verificarHierarquia(elemento) {
      if (elemento.hierarquia >= 1) {
        return "hierarquia1";
      }
      return "";
    },

    notLastElement(elemento) {
      if (elemento.mfi_e_ou) return true;
      return false;
    },

    removerElemento(
      elemento,
      elementoHierarquia = "unset",
      elementoId = "unset"
    ) {
      elemento.filtro_id = 1;
      elemento.ope_titulo = "";
      elemento.mfi_valor = "";
      elemento.mfi_e_ou = "AND";
      elemento.mfi_valor_2 = null;
      elemento.operador_id = 12;
      elemento.contexto_id = null;

      this.listaDeFiltros.splice(this.listaDeFiltros.indexOf(elemento), 1);
    },
    adicionarOuRemoverBtnClick(elemento) {
      const tipo = this.verificarIndexDoElemento(elemento);
      if (tipo === "adicionar" || this.group == 0) {
        if (this.listaDeFiltros.hierarquia !== 0) {
          this.listaDeFiltros[
            this.listaDeFiltros.indexOf(elemento)
          ].listaDeFiltros.push({
            fil_titulo: "Novo Filtro",
            hierarquia: elemento.hierarquia + 1,
            listaDeFiltros: []
          });
        } else {
          this.listaDeFiltros.push({
            fil_titulo: "Novo Filtro",
            hierarquia: elemento.hierarquia,
            listaDeFiltros: []
          });
        }
      } else {
        this.listaDeFiltros.splice(this.listaDeFiltros.indexOf(elemento), 1);
      }
    },
    verificarIndexDoElemento(elemento) {
      if (this.listaDeFiltros.indexOf(elemento) == 0) {
        return "adicionar";
      } else {
        return "remover";
      }
    },
    possuiFiltroObrigatorio(elemento) {
      return !!Utils.deepSearch(elemento, "modelo_filtro_obrigatorio_id");
    }
  },
  computed: {
    camposFiltroComputed() {
      return this.camposPossiveisDoFiltro.map((v) => {
        return { text: v.fil_titulo, value: v.fil_campo };
      });
    },
    operadoresTitulo() {
      return this.opreadoresFiltro.map((v) => {
        return { text: v.ope_titulo, value: v.ope_titulo };
      });
    }
  },
  mounted() {
    const init = async () => {
      if (this.contextFunctions != null) {
        this.funcoesContextoTodas = [...this.contextFunctions];
        this.funcoesContexto = this.contextFunctions.map((v) => {
          return v.con_titulo;
        });
      } else {
        const contextFunctions = await ApiService.get(
          API_LINKS.campanha + "/funcao-contexto"
        );
        this.funcoesContextoTodas = contextFunctions.data.funcoes;
        this.funcoesContexto = contextFunctions.data.funcoes.map((v) => {
          return v.con_titulo;
        });
      }
    };
    init().then((_) => {
      this.listaDeFiltros.forEach((filtro) => {
        if (filtro.fil_tipo !== "INP") {
          // console.log("caiu aqui");
          filtro.isFiltroDinamico = true;
          filtro.isFiltroDinamicoInput = true;
          filtro.dinamycFilterOptions = [];
          filtro.dinamycFilterOptionsWithObj = [];
          if (this.isDraggable) {
            // console.log("caiu aqui2");
            this.modificarFiltroIdDoCampo(filtro, filtro.mfi_valor);
          } else {
            filtro.isFiltroDinamicoInput = false;
          }

          filtro.filtroDinamicoMockValue = filtro.mfi_valor;
        } else {
          filtro.isFiltroDinamico = false;
          filtro.isFiltroDinamicoInput = false;
        }
        if (filtro.contexto_id !== null) {
          filtro.isContextFunction = true;
          const context_function_object = this.funcoesContextoTodas.find(
            (v) => v.contexto_id === filtro.contexto_id
          );
          if (context_function_object) {
            filtro.contexto_nome = context_function_object.con_titulo;
          }
          this.$forceUpdate();
        } else {
          filtro.isContextFunction = false;
          this.$forceUpdate();
        }
      });
    });
  }
};
</script>
<style scoped lang="scss">
@import "../../../../../../assets/sass/pages/nestedComponent/nested.scss";
/* /* ::v-deep .vs__dropdown-toggle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1 !important;
} */

::v-deep .select2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
}

::v-deep .select2-container .select2-selection--single {
  height: unset !important;
}
::v-deep .select2-container--default .select2-selection--single {
  border: none !important;
  line-height: unset !important;
}
::v-deep
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: unset !important;
}

.non-editable {
  pointer-events: none;
  opacity: 0.9;
}
.drag-item {
  display: flex;
  align-items: center;
  gap: 8px;
}
.drag-handle {
  cursor: move;
  color: #525e75;
  user-select: none;
}
.no-drag {
  cursor: not-allowed;
}

.filtro-obrigatorio {
  background-color: #a1b7dd !important; /* neutral grey background */
  border: 1px solid #525e75 !important;
  //opacity: 0.85;
}

.filtro-obrigatorio .form-control:disabled {
  background-color: #c8d0d7 !important;
  color: #525e75 !important;
  pointer-events: none !important;
  opacity: 0.7;
}
</style>
