var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',{staticClass:"dragArea",attrs:{"tag":"ul","handle":".drag-handle","animation":"200","filter":'.no-drag',"list":_vm.listaDeFiltros,"group":{ name: 'group-' + _vm.group },"prevent-on-filter":true,"options":{
    disabled: !_vm.isDraggable
  },"disabled":_vm.possuiFiltroObrigatorio(_vm.listaDeFiltros)}},_vm._l((_vm.listaDeFiltros),function(el,idx){return _c('li',{key:el.name,staticClass:"main-li",class:[
      ("cor-da-hierarquia" + (el.hierarquia)),
      { 'no-drag': !el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id },
      {
        'drag-handle': !_vm.possuiFiltroObrigatorio(_vm.listaDeFiltros)
      },
      {
        'filtro-obrigatorio': _vm.possuiFiltroObrigatorio(el)
      }
    ]},[_c('div',{staticClass:"card-toolbar"},[(el.hasOwnProperty('mfi_e_ou'))?_c('b-form-radio-group',{staticClass:"logica-condicional-input",class:[
          ("border-hierarchy-" + (el.hierarquia)),
          { 'non-editable': !!el.modelo_filtro_obrigatorio_id }
        ],staticStyle:{"z-index":"10"},attrs:{"options":_vm.agrupamento.condicionais,"required":"","buttons":true},model:{value:(el.mfi_e_ou),callback:function ($$v) {_vm.$set(el, "mfi_e_ou", $$v)},expression:"el.mfi_e_ou"}}):_vm._e()],1),_c('div',{staticClass:"d-flex",class:!(Array.isArray(el.listaDeFiltros) && el.listaDeFiltros.length)
          ? 'w-100'
          : ''},[(!(Array.isArray(el.listaDeFiltros) && el.listaDeFiltros.length))?_c('div',{staticClass:"row inputs-main-container"},[_c('div',{staticClass:"col-4 label-input-drag-container"},[_c('pv-dropdown',{staticClass:"form-control",class:!_vm.isDraggable ? 'undraggable-input' : '',attrs:{"options":_vm.camposFiltroComputed,"dataKey":"value","required":"","placeholder":"Nenhum selecionado","optionLabel":"text","optionValue":"value","emptyFilterMessage":"Nenhum resultado","filter":true,"appendTo":"body","disabled":!!el.modelo_filtro_obrigatorio_id},on:{"change":function($event){return _vm.modificarFiltroIdDoCampo(
                el,
                ("input-valor-" + idx + "-" + (el.hierarquia))
              )}},model:{value:(el.fil_campo),callback:function ($$v) {_vm.$set(el, "fil_campo", $$v)},expression:"el.fil_campo"}}),_c('label',{staticClass:"label-input-drag"},[_vm._v("Campo")])],1),_c('div',{staticClass:"col-3 label-input-drag-container"},[_c('pv-dropdown',{staticClass:"form-control",class:!_vm.isDraggable ? 'undraggable-input' : '',attrs:{"options":_vm.operadoresTitulo,"optionLabel":"text","optionValue":"value","appendTo":"body","required":"","filter":true,"disabled":!!el.modelo_filtro_obrigatorio_id},on:{"change":function($event){return _vm.verificarOperadorDeTituloParaSalvarNoId(el)}},model:{value:(el.ope_titulo),callback:function ($$v) {_vm.$set(el, "ope_titulo", $$v)},expression:"el.ope_titulo"}}),_c('label',{staticClass:"label-input-drag"},[_vm._v("Condição")])],1),_c('div',{staticClass:"label-input-drag-container d-flex container-do-input-adriano",class:_vm.verificarCamposDuplos(el) ? 'col-2' : 'col-4'},[((el.type)==='checkbox'&&(
              (!el.isContextFunction && !el.isFiltroDinamicoInput) ||
              (!el.isFiltroDinamico && !el.isContextFunction)
            ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(el.mfi_valor),expression:"el.mfi_valor"}],staticClass:"form-control",attrs:{"disabled":!_vm.isDraggable ||
              el.operador_id === 7 ||
              el.operador_id === 8 ||
              (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id),"id":("input-valor-" + idx + "-" + (el.hierarquia)),"type":"checkbox"},domProps:{"checked":Array.isArray(el.mfi_valor)?_vm._i(el.mfi_valor,null)>-1:(el.mfi_valor)},on:{"change":function($event){var $$a=el.mfi_valor,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(el, "mfi_valor", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(el, "mfi_valor", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(el, "mfi_valor", $$c)}}}}):((el.type)==='radio'&&(
              (!el.isContextFunction && !el.isFiltroDinamicoInput) ||
              (!el.isFiltroDinamico && !el.isContextFunction)
            ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(el.mfi_valor),expression:"el.mfi_valor"}],staticClass:"form-control",attrs:{"disabled":!_vm.isDraggable ||
              el.operador_id === 7 ||
              el.operador_id === 8 ||
              (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id),"id":("input-valor-" + idx + "-" + (el.hierarquia)),"type":"radio"},domProps:{"checked":_vm._q(el.mfi_valor,null)},on:{"change":function($event){return _vm.$set(el, "mfi_valor", null)}}}):(
              (!el.isContextFunction && !el.isFiltroDinamicoInput) ||
              (!el.isFiltroDinamico && !el.isContextFunction)
            )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(el.mfi_valor),expression:"el.mfi_valor"}],staticClass:"form-control",attrs:{"disabled":!_vm.isDraggable ||
              el.operador_id === 7 ||
              el.operador_id === 8 ||
              (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id),"id":("input-valor-" + idx + "-" + (el.hierarquia)),"type":el.type},domProps:{"value":(el.mfi_valor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(el, "mfi_valor", $event.target.value)}}}):_vm._e(),(el.isFiltroDinamico && el.isFiltroDinamicoInput)?_c('Select2',{staticClass:"form-control",staticStyle:{"position":"relative !important"},attrs:{"options":el.dinamycFilterOptions,"disabled":!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id},on:{"change":function($event){return _vm.findValueDinamicFilter($event, el)}},model:{value:(el.filtroDinamicoMockValue),callback:function ($$v) {_vm.$set(el, "filtroDinamicoMockValue", $$v)},expression:"el.filtroDinamicoMockValue"}}):_vm._e(),(el.isContextFunction)?_c('Select2',{staticClass:"form-control",staticStyle:{"position":"relative !important"},attrs:{"options":_vm.funcoesContexto,"disabled":!_vm.isDraggable ||
              (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id)},on:{"change":function($event){return _vm.findValueContextFunction($event, el)}},model:{value:(el.contexto_nome),callback:function ($$v) {_vm.$set(el, "contexto_nome", $$v)},expression:"el.contexto_nome"}}):_vm._e(),_c('label',{staticClass:"label-input-drag"},[_vm._v(_vm._s(el.isFiltroDinamico && el.isFiltroDinamicoInput ? "Filtro Dinâmico" : !el.isContextFunction ? "Valor" : "Função de Contexto"))])],1),(_vm.verificarCamposDuplos(el))?_c('div',{staticClass:"label-input-drag-container col-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(el.mfi_valor_2),expression:"el.mfi_valor_2"}],staticClass:"form-control",attrs:{"type":"text","disabled":!_vm.isDraggable ||
              (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id)},domProps:{"value":(el.mfi_valor_2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(el, "mfi_valor_2", $event.target.value)}}}),_c('label',{staticClass:"label-input-drag"},[_vm._v("Valor 2")])]):_vm._e(),(!_vm.verificarCamposDuplos(el) && !el.isFiltroDinamico)?_c('div',{staticClass:"label-input-drag-container switch-container col-1"},[_c('InputSwitch',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Função de Contexto'),expression:"'Função de Contexto'"}],attrs:{"disabled":!_vm.isDraggable ||
              (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id)},on:{"click":function($event){return _vm.contextFunctionSwitch(el)}},model:{value:(el.isContextFunction),callback:function ($$v) {_vm.$set(el, "isContextFunction", $$v)},expression:"el.isContextFunction"}})],1):_vm._e(),(el.isFiltroDinamico)?_c('div',{staticClass:"label-input-drag-container switch-container col-1"},[_c('InputSwitch',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Filtro Dinâmico'),expression:"'Filtro Dinâmico'"}],attrs:{"disabled":!_vm.isDraggable ||
              (!el.mfo_is_editable && !!el.modelo_filtro_obrigatorio_id)},on:{"click":function($event){return _vm.dinamicFilterSwitch(el)}},model:{value:(el.isFiltroDinamicoInput),callback:function ($$v) {_vm.$set(el, "isFiltroDinamicoInput", $$v)},expression:"el.isFiltroDinamicoInput"}})],1):_vm._e()]):_vm._e(),(_vm.isDraggable && !_vm.possuiFiltroObrigatorio(el))?_c('div',{staticClass:"buttons-container",class:Array.isArray(el.listaDeFiltros) && el.listaDeFiltros.length
            ? 'flex-column'
            : ''},[(_vm.group === 0 || _vm.group === 1 || _vm.group === 2)?_c('b-button',{class:[
            'button-plus',
            { 'non-editable': !!el.modelo_filtro_obrigatorio_id }
          ],staticStyle:{"background":"rgba(255, 255, 255, 0.3)"},attrs:{"variant":!!el.modelo_filtro_obrigatorio_id ? 'disabled' : 'outline-primary',"disabled":_vm.possuiFiltroObrigatorio(el)},on:{"click":function($event){return _vm.adicionarElemento(el)}}},[_vm._v(" +")]):_vm._e(),_c('b-button',{staticClass:"button-plus d-block",class:[
            'button-plus',
            'd-block',
            { 'non-editable': !!el.modelo_filtro_obrigatorio_id }
          ],staticStyle:{"background":"rgba(255, 255, 255, 0.3)"},attrs:{"variant":!!el.modelo_filtro_obrigatorio_id ? 'disabled' : 'outline-danger'},on:{"click":function($event){return _vm.removerElemento(el, el.hierarquia, el.modelo_filtro_id)}}},[_vm._v(" -")]),(!_vm.possuiFiltroObrigatorio(_vm.listaDeFiltros))?_c('b-button',{class:[
            'button-plus',
            'd-block',
            { 'non-editable': !!el.modelo_filtro_obrigatorio_id }
          ],staticStyle:{"background":"rgba(255, 255, 255, 0.3)"},attrs:{"variant":!!el.modelo_filtro_obrigatorio_id ? 'disabled' : 'outline-warning'},on:{"click":function($event){return _vm.duplicarFiltro(el, idx, el.listaDeFiltros)}}},[_c('i',{staticClass:"flaticon2-copy"})]):_vm._e()],1):_vm._e()]),_c('nested-draggable',{attrs:{"group":_vm.group + 1,"listaDeFiltros":el.listaDeFiltros,"camposPossiveisDoFiltro":_vm.camposPossiveisDoFiltro,"opreadoresFiltro":_vm.opreadoresFiltro,"isDraggable":_vm.isDraggable,"sistemaLicenciadaId":_vm.sistemaLicenciadaId,"contextFunctions":_vm.contextFunctions}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }